import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Constants, Role } from '../../shared/constants';
import { User } from '../../shared/models/user.model';
import { UserService } from '../../shared/services/user-service.service';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../environment';
import { RolesService } from '../../shared/services/roles-service.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  readonly ADMIN_ROLE: string = Role.ADMIN;
  toggledMenu = false;
  regions: Array<string>;
  features: Map<string, boolean>;

  public locale: any;
  public user: User;
  @Output() voted = new EventEmitter<User>();

  constructor(
    private userService: UserService,
    public router: Router,
    private translateService: TranslateService,
    public rolesService: RolesService
  ) {
    this.regions = environment.regions;
    this.features = environment.features;
  }

  ngOnInit() {
    this.userService.login();
    this.userService
      .getUser()
      .pipe(
        filter((val) => val !== null),
        take(1)
      )
      .subscribe((x) => {
        this.user = x;
      });
  }

  isLoggedIn(): User {
    return this.user;
  }

  logout() {
    this.userService.logout();
  }

  changeLanguage(lang: string) {
    console.info("language changed");
    this.translateService.use(lang);
  }
}
